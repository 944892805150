import React, { useState, useEffect } from 'react';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { addDoc, collection } from 'firebase/firestore';
import { db } from './firebase';
import { useNavigate } from 'react-router-dom';
import './VideoUpload.css';

const VideoUpload = () => {
  const [videoFile, setVideoFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [progress, setProgress] = useState(0);
  const [ipAddress, setIpAddress] = useState('');
  const [videoFileName, setVideoFileName] = useState('Выбрать видеофайл');
  const [previewImageName, setPreviewImageName] = useState('Выбрать превью изображение');
  const [uploadTask, setUploadTask] = useState(null);
  const [isPrivate, setIsPrivate] = useState(true); // Флаг для доступа по ссылке
  const [isAdultContent, setIsAdultContent] = useState(false); // Флаг для контента 18+
  const navigate = useNavigate();

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        setIpAddress(data.ip);
      } catch (error) {
        console.error('Ошибка получения IP-адреса:', error);
      }
    };

    fetchIpAddress();
  }, []);

  const handleVideoFileChange = (event) => {
    const file = event.target.files[0];
    setVideoFile(file);
    setVideoFileName(file ? file.name : 'Выбрать видеофайл');
  };

  const handlePreviewImageChange = (event) => {
    const file = event.target.files[0];
    setPreviewImage(file);
    setPreviewImageName(file ? file.name : 'Выбрать превью изображение');
  };

  const handleUpload = async () => {
    if (!videoFile || !previewImage || !title || !description) {
      alert('Пожалуйста, заполните все поля и выберите файлы.');
      return;
    }

    try {
      const storage = getStorage();

      // Загрузка превью в Storage
      const previewImageRef = ref(storage, `previewImages/${previewImage.name}`);
      const previewUploadTask = uploadBytesResumable(previewImageRef, previewImage);
      const previewImageUrl = await new Promise((resolve, reject) => {
        previewUploadTask.on('state_changed', null, reject, async () => {
          const url = await getDownloadURL(previewUploadTask.snapshot.ref);
          resolve(url);
        });
      });

      // Загрузка видео в Storage
      const videoRef = ref(storage, `videos/${videoFile.name}`);
      const newUploadTask = uploadBytesResumable(videoRef, videoFile);
      setUploadTask(newUploadTask); // Сохраняем ссылку

      newUploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setProgress(progress);
        },
        (error) => {
          console.error('Ошибка при загрузке файла:', error);
        },
        async () => {
          const videoUrl = await getDownloadURL(newUploadTask.snapshot.ref);

          // Сохранение данных видео в базе данных
          const docRef = await addDoc(collection(db, 'videos'), {
            title,
            description,
            previewImageUrl,
            videoUrl,
            createdAt: new Date(),
            ipAddress,
            access: isPrivate ? 'linkOnly' : 'public', // Указание, что видео доступно только по ссылке
            isPrivate, // Флаг для фильтрации в базе данных
            isAdultContent, // Флаг 18+ контента
          });

          alert('Видео успешно загружено и доступно по ссылке!');
          navigate(`/video/${docRef.id}`); // Перенаправление на страницу видео после загрузки
        }
      );
    } catch (error) {
      console.error('Ошибка при загрузке видео:', error);
      alert('Не удалось загрузить видео.');
    }
  };

  // Обработка кнопки отмены загрузки
  const handleCancel = () => {
    if (uploadTask) {
      uploadTask.cancel(); // Отменяем загрузку 
      setUploadTask(null);
    }
    setTitle('');
    setDescription('');
    setVideoFile(null);
    setPreviewImage(null);
    setProgress(0);
    setVideoFileName('Выбрать видеофайл');
    setPreviewImageName('Выбрать превью изображение');
  };

  return (
    <div className="upload-container">
      <h2>Загрузить видео</h2>
      <div className="form-group">
        <label>Название</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label>Описание</label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        ></textarea>
      </div>
      <div className="form-group file-input-group">
        <label htmlFor="previewImage">Превью изображение</label>
        <input
          type="file"
          accept="image/*"
          id="previewImage"
          onChange={handlePreviewImageChange}
          required
          hidden
        />
        <button className="custom-button file-button" onClick={() => document.getElementById('previewImage').click()}>
          {previewImageName}
        </button>
      </div>
      <div className="form-group file-input-group">
        <label htmlFor="videoFile">Файл видео</label>
        <input
          type="file"
          accept="video/*"
          id="videoFile"
          onChange={handleVideoFileChange}
          required
          hidden
        />
        <button className="custom-button file-button" onClick={() => document.getElementById('videoFile').click()}>
          {videoFileName}
        </button>
      </div>
      <div className="form-group">
        <label>
          <input
            type="checkbox"
            checked={isPrivate}
            onChange={() => setIsPrivate(!isPrivate)}
          />
          Доступен только по ссылке (не отображается в рекомендациях)
        </label>
      </div>
      <div className="form-group">
        <label>
          <input
            type="checkbox"
            checked={isAdultContent}
            onChange={() => setIsAdultContent(!isAdultContent)}
          />
          18+ контент (требует подтверждения возраста)
        </label>
      </div>
      <button className="custom-button upload-button" onClick={handleUpload}>
        Загрузить
      </button>
      <button className="custom-button cancel-button" onClick={handleCancel}>
        Отмена
      </button>
      {progress > 0 && (
        <div className="progress-container">
          <div className="progress-bar" style={{ width: `${progress}%` }}>
            {progress}%
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoUpload;
