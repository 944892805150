import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const [dayClass, setDayClass] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const currentDay = new Date().getDay();
    switch (currentDay) {
      case 1: // Пон
        setDayClass('monday');
        break;
      case 2: // Вторник
        setDayClass('tuesday');
        break;
      case 3: // Среда
        setDayClass('wednesday');
        break;
      case 4: // Четверг
        setDayClass('thursday');
        break;
      case 5: // Пятница
        setDayClass('friday');
        break;
      case 6: // Суббота
        setDayClass('saturday');
        break;
      case 0: // Воскресенье
        setDayClass('sunday');
        break;
      default:
        setDayClass('');
        break;
    }
  }, []);

  return (
    <header className={`header ${dayClass}`}>
      <h1 className="logo">KotekaHUB 😍</h1>
      <div className="menu-icon" onClick={() => navigate('/all')}>
        &#9776;
      </div>
      <nav className="nav-menu">
        <ul>
          <li><Link to="/">Главная</Link></li>
          <li><Link to="/about">О сайте</Link></li>
          <li><Link to="/all">Все ссылки</Link></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
