import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from './firebase';
import { doc, getDoc } from 'firebase/firestore';
import './VideoDetail.css';

const VideoDetailPage = () => {
  const { videoId } = useParams();
  const navigate = useNavigate();
  const [videoData, setVideoData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ageVerified, setAgeVerified] = useState(false); // Состояние проверки возраста
  const [showWarning, setShowWarning] = useState(false); // Состояние для показа вопроса

  useEffect(() => {
    const fetchVideoDetails = async () => {
      try {
        const videoDoc = await getDoc(doc(db, 'videos', videoId));
        if (videoDoc.exists()) {
          const data = videoDoc.data();
          setVideoData(data);
          if (data.isAdultContent) {
            setShowWarning(true); // Показать предупреждение, если контент 18+
          }
        } else {
          console.error('Видео не найдено:', videoId);
        }
      } catch (error) {
        console.error('Ошибка при загрузке видео:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVideoDetails();
  }, [videoId]);

  const handleAgeVerification = (answer) => {
    if (answer === 'yes') {
      navigate('/'); // Перенаправление на главную, если пользователь ответил "да"
    } else {
      setAgeVerified(true); // Разрешить просмотр, если ответ "нет"
      setShowWarning(false); // Скрыть предупреждение
    }
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
      </div>
    );
  }

  if (!videoData) {
    return <div className="error-message">Видео не найдено.</div>;
  }

  return (
    <div className="video-detail-container">
      <div className="video-container" style={{ position: 'relative' }}>
        {videoData.isAdultContent && !ageVerified && (
          <div className="video-blur-overlay">
            <div className="age-verification-box">
              <h3>Это видео содержит контент 18+.</h3>
              <p>Тапали ли вы хомяка?</p>
              <button onClick={() => handleAgeVerification('yes')}>Да</button>
              <button onClick={() => handleAgeVerification('no')}>Нет</button>
            </div>
          </div>
        )}
        <video controls style={ageVerified || !videoData.isAdultContent ? {} : { filter: 'blur(20px)' }}>
          <source src={videoData.videoUrl} type="video/mp4" />
          Ваш браузер не поддерживается. Поддержка - https://discord.gg/7cZtX4xumY
        </video>
      </div>
      <div className="video-info">
        <h2>{videoData.title || 'Название недоступно'}</h2>
        <p>{videoData.description || 'Описание недоступно'}</p>
      </div>
    </div>
  );
};

export default VideoDetailPage;
